export const de = {
  'navbar.news': 'Nachrichten',
  'navbar.aboutUs': 'Über uns',
  'navbar.realEstate': 'Immobilien',
  'navbar.realEstateValuation': 'Immobilienbewertung',
  'navbar.investitions': 'Investitionen',
  'navbar.contact': 'Kontakt',
  'navbar.select.language.placeholder': 'Sprache auswählen',
  'home.hero.title': 'Ihr neues Zuhause',
  'home.hero.subTitle': 'Finden Sie Ihr Traumhaus',
  'home.hero.button.searchRealEstate': 'Suchen Sie nach Ihrer Immobilie',
  'home.hero.button.sellRealEstate': 'Verkaufen Sie Ihre Immobilie',
  'aboutUs.title': 'Wer sind wir?',
  'aboutUs.description.p1':
    'Zugelassene Immobilienmaklerin mit langjähriger Erfahrung auf dem Immobilienmarkt. Absolventin der Universität Szczecin an der Fakultät für Wirtschaft und Management und Postgraduiertenstudium an der Wirtschaftsuniversität Poznań im Bereich Immobilienmanagement - Immobilienbewertung und -zertifizierung sowie Energieaudit von Gebäuden am WSKZ in Wrocław.',
  'aboutUs.description.p2':
    'Das Ergebnis langjähriger Erfahrung und erworbener Kenntnisse ist "HUK Invest Immobilien & Investitionen". Unser Unternehmen bietet Dienstleistungen an, die den gesamten Prozess vom Immobilienhandel über den Bau, die Renovierung, die Investitionsberatung, die Erstellung von Energiedokumenten bis hin zur Unterstützung bei der Beschaffung von immobilienbezogenen Krediten umfassen.',
  'aboutUs.description.p3':
    'Unser Ziel ist es, einen Ort zu schaffen, an dem alle Dienstleistungen auf höchstem Niveau erbracht werden und an dem sich unsere Professionalität und unser Engagement in Ihrer Zufriedenheit und Ihrem Vergnügen niederschlagen.',
  'realEstateView.title': 'Finden Sie Ihr neues Zuhause',
  'realEstateCard.showMore': 'Mehr anzeigen',
  'realEstateView.button.showMoreRealEstate': 'Mehr Immobilien sehen',
  'navigator.title': 'Das können wir für Sie tun:',
  'navigator.button.buyRealEstate': 'Eine Immobilie kaufen',
  'navigator.button.realEstateValuation': 'Immobilien-Expertise',
  'navigator.button.epcOrder': 'CHE-Zertifikat bestellen',
  room: 'Zimmer',
  rooms: 'Zimmer',
  'investitions.title': 'Investitionen',
  'investitions.title.marketAnalysis': 'Marktanalyse',
  'investitions.title.riskAndReturnAssessment': 'Risiko- und Ertragsbewertung',
  'investitions.title.investmentStrategy': 'Auswahl der Investitionsstrategie',
  'investitions.title.profitabilityAnalysis': 'Analyse der Rentabilität',
  'investitions.description':
    'Wir haben uns auf die Beratung im Zusammenhang mit Immobilieninvestitionen spezialisiert. Zu den damit verbundenen Aktivitäten gehören:',
  'investitions.description.marketAnalysis':
    'Wir führen eine umfassende Analyse der aktuellen Situation auf dem Immobilienmarkt durch, um Trends, Preisprognosen und potenzielle Investitionsbereiche zu ermitteln. Dies ermöglicht unseren Kunden, fundierte Investitionsentscheidungen zu treffen.',
  'investitions.description.riskAndReturnAssessment':
    'Wir führen eine Risikoanalyse für bestimmte Investitionen durch und informieren unsere Kunden über potenzielle Risiken und mögliche Erträge.',
  'investitions.description.investmentStrategy':
    'Auf der Grundlage der individuellen Ziele und Präferenzen des Kunden helfen wir bei der Entwicklung einer auf die spezifischen Bedürfnisse zugeschnittenen Anlagestrategie. Diese kann kurz- oder langfristige Investitionen, Investitionen in Gewerbe- oder Wohnimmobilien sowie andere Formen von Immobilieninvestitionen umfassen.',
  'investitions.description.profitabilityAnalysis':
    'Wir führen eine detaillierte Analyse der Rentabilität bestimmter Investitionen durch und berücksichtigen dabei die Kosten für den Kauf, die Instandhaltung und den eventuellen Verkauf der Immobilie. Dies hilft den Kunden, die potenziellen Erträge und Herausforderungen einer bestimmten Investition zu verstehen.',
  'epcOrder.title': 'Energieausweis',
  'epcOrder.description':
    'Das Energieausweis ist ein Dokument, das den Energiebedarf bestimmt, der erforderlich ist, um die Energiebedürfnisse im Zusammenhang mit der Nutzung eines Gebäudes oder eines Teils eines Gebäudes zu decken, d.h. Energie für Heizung und Lüftung, Warmwasserbereitung, Kühlung und im Falle von Nichtwohngebäuden auch Beleuchtung. Die Verpflichtung, in bestimmten Situationen einen Energieausweis für ein Gebäude oder einen Teil eines Gebäudes zu besitzen, ergibt sich aus dem europäischen Recht. Das Ziel der Einführung der Verpflichtung zur Ausstellung von Zertifikaten ist die Förderung von energieeffizientem Bauen und die Steigerung des öffentlichen Bewusstseins für die Möglichkeiten der Energieeinsparung in Gebäuden. Dank der Informationen im Zertifikat kann der Eigentümer, Mieter oder Nutzer des Gebäudes den ungefähren jährlichen Energiebedarf und damit die mit dem Energieverbrauch verbundenen Unterhaltskosten ermitteln.',
  'error.message.companyDataError':
    'Leider konnten die Kontaktdaten des Unternehmens nicht abgerufen werden, wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen [CVHI-01].',
  'error.message.realEstateDataError':
    'Leider konnten keine Immobilien abgerufen werden, wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen [REDHI-01].',
  'error.message.searchFormError':
    'Leider konnten keine Suchparameter abgerufen werden, wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen [SFHI-01].',
  'error.message.newsDataError':
    'Leider konnten keine Nachrichten abgerufen werden, wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen [NHI-01].',
  'error.message.teamDataError':
    'Leider konnten keine Teamdaten abgerufen werden, wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen [THI-01].',
  'error.message.partnerCompanyDataError':
    'Leider konnten keine Daten zu Geschäftspartnern abgerufen werden, wir entschuldigen uns für die Unannehmlichkeiten und bitten Sie, es später erneut zu versuchen [PCHI-01].',
  'contact.title': 'Nehmen Sie Kontakt mit uns auf',
  'contact.description':
    'Wählen Sie die Kontaktform, die am besten zu Ihnen passt. Mit unserem modernen Ansatz und unserer Flexibilität passen wir uns Ihren Bedürfnissen an.',
  'contact.socialMediaDescription': 'Folgen Sie uns in sozialen Medien',
  'footer.copyRight': 'Copyright c 2024. Alle Rechte vorbehalten -  Webowi.pl',
  'realEstateValuation.title': 'Immobilienbewertung',
  'realEstateValuation.description':
    'Dieser Begriff steht für einen umfassenden Ansatz bei der Bewertung von Immobilien, der sowohl finanzielle Aspekte (Bewertung) als auch Fragen der Energieeffizienz (Energieausweise) umfasst. Die Immobilienbewertung umfasst eine Analyse des Marktwerts, des Investitionspotenzials sowie eine Bewertung der Energieeffizienz, die für Immobilieneigentümer, Investoren oder potenzielle Käufer wichtig sein kann.',
  'realEstateValuation.subTitle': 'Immobilienexpertise in 4 einfachen Schritten:',
  'valuation.step1.title': '1. Treffen',
  'valuation.step1.description':
    'Lernen wir uns kennen, zeigen Sie uns Ihre Immobilie, besprechen wir alle rechtlichen Aspekte und erstellen wir den besten Verkaufsplan.',
  'valuation.step2.title': '2. Vermarktung',
  'valuation.step2.description':
    'Wir bereiten für Sie die bestmögliche Vermarktung und Werbung für Ihre Immobilie vor, einschließlich Fotos, die die besten Aspekte Ihrer Immobilie zum Ausdruck bringen.',
  'valuation.step3.title': '3. Präsentation',
  'valuation.step3.description': 'Wir präsentieren Ihre Immobilie dem zukünftigen Käufer.',
  'valuation.step4.title': '4. Verkauf',
  'valuation.step4.description':
    'Sobald der Käufer interessiert ist, unterzeichnen wir den Vertrag und übergeben die Schlüssel.',
  'contactLink.realEstateCostCalculato': 'Immobilienkosten Rechner',
  'realEstate.realEstate.notFound':
    'Leider haben wir keine Immobilien gefunden, die die angegebenen Kriterien erfüllen.',
  'pageWrapper.findRealEstate': 'Suchen Sie eine Immobilie',
  'pageWrapper.news': 'Nachrichten',
  'selectBox.propertyType': 'Immobilientyp',
  'selectBox.location': 'Lage',
  'fromToInput.priceFrom': 'Preis von',
  'fromToInput.priceTo': 'Preis bis',
  'fromToInput.areaFrom': 'Fläche von',
  'fromToInput.areaTo': 'Fläche bis',
  'fromToInput.roomsFrom': 'Zimmer von',
  'fromToInput.roomsTo': 'Zimmer bis',
  'searchForm.buttonTyped.find': 'Suchen',
  'realEstateOfferDetail.title': 'Angebotsdetails',
  'realEstateOfferDetail.price': 'Preis',
  'realEstateOfferDetail.pricePerM2': 'Preis pro m2',
  'realEstateOfferDetail.area': 'Fläche',
  'realEstateOfferDetail.roomsNumber': 'Anzahl der Zimmer',
  'realEstateOfferDetail.numberOfFloors': 'Anzahl der Stockwerke',
  'realEstateOfferDetail.floor': 'Etage',
  'realEstateOfferDetail.code': 'Code',
  'realEstateOfferDetail.location': 'Lage',
  'realEstateOfferDetail.type': 'Typ',
  'realEstateOfferDetail.condition': 'Zustand',
  'realEstateDetail.description': 'Beschreibung',
  createdAt: 'Erstellt am: ',
  'team.title': 'Unser Team',
  'team.showMore': 'Mehr anzeigen',
  'team.showAll': 'Das gesamte Team anzeigen',
  'partnerCompany.title': 'Geschäftspartner',
};
