import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import I18n from 'services/translation/I18n';

export const DetailListItem = ({ name, value, sufix }) => (
  <StyledDetail key={name}>
    <StyledDetailTitle>
      <I18n trans={name} />:
    </StyledDetailTitle>{' '}
    {value} {sufix}
  </StyledDetail>
);

const StyledDetail = styled.div``;
const StyledDetailTitle = styled.span`
  color: ${colors.glamourGold};
  font-weight: bold;
`;

DetailListItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  sufix: PropTypes.string,
};

DetailListItem.defaultProps = {
  value: '',
  sufix: '',
};
