import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { colors } from 'utils/colors';
import I18n from 'services/translation/I18n';

export const Button = ({ href, text }) => (
  <StyledButton to={href} alt={text}>
    <I18n trans={text} />
  </StyledButton>
);

Button.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const StyledButton = styled(Link)`
  text-align: center;
  appearance: none;
  background-color: ${colors.lightBlack};
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: ${colors.white};
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
  margin: 1rem;
  font-size: 1rem;
  padding: 1rem 3rem;
  border: 2px solid ${colors.rottenGreen};
  box-shadow: 8px 8px 24px -13px rgba(66, 68, 90, 1);

  &:hover {
    background-color: ${colors.mediumDark};
    text-decoration: none;
    transition-duration: 0.1s;
  }

  &:focus {
    outline: 1px transparent;
  }
`;
