import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LiaBedSolid } from 'react-icons/lia';
import { SlSizeFullscreen } from 'react-icons/sl';
import { Link } from 'react-router-dom';
// import {formatCurrency} from "react-native-format-currency";
import { Image } from 'components/molecues/Image/Image';
import { Button } from 'components/atoms/Button/Button';
import { colors } from 'utils/colors';
import { maxDeviceSize } from 'utils/deviceSize';
import I18n from 'services/translation/I18n';
import LinkController from 'DDD/Link/Ui/LinkController';
import { pages } from 'constans/pages';

const RealEstateCard = ({ src, title, price, roomsNumber, area, bgcolor, id, currency }) => {
  const linkController = new LinkController();
  const translatedRooms = roomsNumber > 1 ? 'rooms' : 'room';
  const priceNumber = price !== 'undefined' ? Number(price.toString()) : null;
  const priceFormatted = new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency,
  }).format(priceNumber);
  return (
    <StyledCardContainer bgcolor={bgcolor}>
      <StyledImageContainer to={linkController.getRouteByName(pages.realEstateDetail, { id })}>
        <Image
          src={src}
          alt={title}
          style={{
            width: '100%',
            boxshadow: 'none',
            border: 'none',
            transform: 'none',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          }}
        />
      </StyledImageContainer>
      <StyledDescriptionContainer>
        <StyledTitle>{title}</StyledTitle>
        <StylePrice>
          <I18n trans="realEstateOfferDetail.price" /> {priceFormatted}
        </StylePrice>
      </StyledDescriptionContainer>
      <StyledShorDescriptionWrapper>
        {roomsNumber !== 0 && (
          <StyledShorDescriptionContainer>
            <div>
              <LiaBedSolid />
            </div>
            <div>
              {`${roomsNumber}`} <I18n trans={translatedRooms} />
            </div>
          </StyledShorDescriptionContainer>
        )}

        <StyledShorDescriptionContainer>
          <div>
            <SlSizeFullscreen />
          </div>
          <div>{area} m2</div>
        </StyledShorDescriptionContainer>
      </StyledShorDescriptionWrapper>
      <StyledButtonWrapper>
        <Button
          href={linkController.getRouteByName(pages.realEstateDetail, { id })}
          text="realEstateCard.showMore"
        />
      </StyledButtonWrapper>
    </StyledCardContainer>
  );
};

RealEstateCard.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  roomsNumber: PropTypes.number,
  area: PropTypes.string.isRequired,
  bgcolor: PropTypes.string,
  id: PropTypes.string.isRequired,
  currency: PropTypes.string,
};

RealEstateCard.defaultProps = {
  bgcolor: colors.white,
  roomsNumber: 0,
  currency: 'PLN',
};

const StyledCardContainer = styled.div`
  border: 1px solid ${colors.rottenGreen};
  background-color: ${({ bgcolor }) => bgcolor || colors.white};
  margin: 1rem;
  box-shadow: 8px 8px 16px -14px rgba(66, 68, 90, 1);
  max-width: 400px;
  border-radius: 12px;
  @media ${maxDeviceSize.tablet} {
    margin: 2rem;
  }
`;

const StyledImageContainer = styled(Link)`
  filter: brightness(70%);

  &:hover {
    filter: brightness(100%);
    transition: 0.5s;
    cursor: pointer;
  }
`;

const StyledDescriptionContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-bottom: 1px solid ${colors.rottenGreen};
`;

const StyledTitle = styled.h2`
  font-size: 1.5rem;
`;
const StylePrice = styled.h2`
  font-size: 1rem;
`;

const StyledShorDescriptionWrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-around;
`;

const StyledShorDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  border-top: 1px solid ${colors.rottenGreen};
  display: flex;
  justify-content: space-around;
`;

export default RealEstateCard;
