import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { CiCalculator1 } from 'react-icons/ci';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MainLayout } from 'layouts/MainLayout';
import jsonToArrayDeserializer from 'serializers/jsonToArrayDeserializer';
import { Image } from 'components/molecues/Image/Image';
import { colors } from 'utils/colors';
import SectionLayout from 'layouts/SectionLayout';
import { maxDeviceSize } from 'utils/deviceSize';
import { ShortRealEstateIconDescription } from 'components/molecues/ShortRealEstateIconDescription/ShortRealEstateIconDescription';
import RealEstateOfferDetails from 'components/molecues/RealEstateOfferDetails/RealEstateOfferDetails';
import { getRealEstate, logError } from 'services/ResourceApiManager';
import { Loading } from 'components/atoms/Loading/Loading';
import { ContactLink } from 'components/atoms/ContactLink/ContactLink';
import I18n, { getLanguage } from 'services/translation/I18n';
import { ReloadContext } from 'context/ReloadContext';
import GallerySlider from 'components/organisms/GallerySlider/GallerySlider';
import { getPriceFormatted } from 'services/PriceManager';
import { Hr } from 'components/atoms/Hr/Hr';
import { getImageUrl } from 'services/ImageProvider';
import parse from 'html-react-parser';

const RealEstateDetail = () => {
  const [realEstate, setRealEstate] = useState({});
  const [price, setPrice] = useState('');
  const [priceForMeter, setPriceForMeter] = useState('');
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { reload } = useContext(ReloadContext);

  const retrieveRealEstate = (realEstateId) => {
    const lang = getLanguage();
    getRealEstate(realEstateId, { lang })
      .then((response) => {
        setLoading(false);
        const { data } = response;
        setRealEstate(data);

        if (typeof data.price !== 'undefined') {
          const priceNumber = data.price !== 'undefined' ? Number(data.price.toString()) : null;
          setPrice(getPriceFormatted(data.price.toString(), 'pl-PL', data.currency));
          setPriceForMeter((priceNumber / parseFloat(data.area)).toFixed(2).toString());
        }

        setImages(jsonToArrayDeserializer(data.images));
      })
      .catch((error) => {
        logError(error, 'REDHI-01');
        toast.error(<I18n trans="error.message.realEstateDataError" />, {
          position: 'top-right',
          autoClose: 5000,
          theme: 'colored',
        });
      });
  };

  useEffect(() => {
    retrieveRealEstate(id);
  }, [id, reload]);
  if (loading) {
    return (
      <MainLayout>
        <SectionLayout>
          <Loading />
        </SectionLayout>
        <ToastContainer />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <SectionLayout>
        <StyledHeadingContainer>
          <StyledHeading>{realEstate.title}</StyledHeading>
          <StyledIconDataLocationContainer>
            <FaMapMarkerAlt style={{ color: colors.glamourGold }} /> {realEstate.location}
          </StyledIconDataLocationContainer>
        </StyledHeadingContainer>
        <ShortRealEstateIconDescription
          price={String(price)}
          roomcount={String(realEstate.roomCount)}
          priceForMeter={priceForMeter}
          area={String(realEstate.area)}
          code={realEstate.code}
          currency={realEstate.currency}
        />
        <StyledMainImageContainer>
          <Image
            src={getImageUrl(realEstate.mainImage)}
            alt={realEstate.title}
            bgcolor={colors.white}
            width="100%"
            height="100%"
          />
        </StyledMainImageContainer>
        <Hr />
        <StyledDescriptionWrapper>
          <RealEstateOfferDetails
            price={String(price)}
            priceForMeter={String(priceForMeter)}
            realEstate={realEstate}
            currency={realEstate.currency}
          />
          <StyledDescriptionContainer>
            <StyledDetailTitle>
              <I18n trans="realEstateDetail.description" />
            </StyledDetailTitle>
            <StyledDescription>{parse(realEstate.description || '')}</StyledDescription>
          </StyledDescriptionContainer>
        </StyledDescriptionWrapper>
        <StyledGalleryListWrapper>
          <GallerySlider images={images} />
        </StyledGalleryListWrapper>
        <ContactLink
          href={process.env.REACT_APP_REAL_ESTATE_EXTERNAL_CALULATOR_COST}
          alt="Real estate cost calculator"
          Icon={CiCalculator1}
          textAfter="contactLink.realEstateCostCalculator"
          ariaLabel="Real estate cost calculator"
        />
      </SectionLayout>
      <ToastContainer />
    </MainLayout>
  );
};

const StyledHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
`;
const StyledHeading = styled.h1`
  font-size: 2.5rem;
`;
const StyledDescriptionWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: flex-start;

  @media ${maxDeviceSize.tablet} {
    flex-direction: column;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
`;
const StyledDetailTitle = styled.h2`
  font-size: 1.6rem;
  padding: 1rem;
`;

const StyledDescriptionContainer = styled.div`
  font-size: 1.2rem;
`;
const StyledDescription = styled.p``;
const StyledMainImageContainer = styled.div``;
const StyledIconDataLocationContainer = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

const StyledGalleryListWrapper = styled.div`
  padding: 2rem;
`;

export default RealEstateDetail;
